// third-party
import { FormattedMessage } from 'react-intl';
import { FaCog, FaTags, FaMapMarkerAlt, FaCheckCircle, FaQuestionCircle, FaBolt, FaPercentage, FaBell, FaFileAlt } from 'react-icons/fa';
import { TbPhotoCog  } from "react-icons/tb";
import { HiOutlineTicket } from "react-icons/hi2";

let accessList = JSON.parse(localStorage.getItem('menuAccess') || "[]");

if (!Array.isArray(accessList)) {
  accessList = [];
}

const roleId = localStorage.getItem('roleId');

if (roleId === "1") {
  accessList = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
}

const hasAccess = accessList.some(id => [14, 15, 16, 17, 18, 19, 20, 21, 22, 23].includes(id));

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

let applications = {
  id: 'Configurations',
  title: hasAccess ? <FormattedMessage id="Configurations" /> : null,
  icon: FaCog,
  type: 'group',
  children: [
    accessList.includes(14) && {
      id: 'Configurations',
      title: <FormattedMessage id="Configurations" />,
      type: 'collapse',
      icon: FaTags,
      breadcrumbs: true,
      children: [
        {
          id: 'Category',
          title: <FormattedMessage id="Category" />,
          type: 'item',
          url: '/apps/category'
        },
        {
          id: 'Sub Category',
          title: <FormattedMessage id="Sub Category" />,
          type: 'item',
          url: '/apps/subcategory'
        },
        {
          id: 'Product Type',
          title: <FormattedMessage id="Product Type" />,
          type: 'item',
          url: '/apps/product-type'
        },
        {
          id: 'Attribute',
          title: <FormattedMessage id="Attribute" />,
          type: 'item',
          url: '/apps/variant'
        },
        {
          id: 'Attribute Value',
          title: <FormattedMessage id="Attribute Value" />,
          type: 'item',
          url: '/apps/variant-value'
        },
        {
          id: 'Preferences',
          title: <FormattedMessage id="Preferences" />,
          type: 'item',
          url: '/apps/configurations'
        }
      ]
    },
    accessList.includes(15) && {
      id: 'Location',
      title: <FormattedMessage id="Location" />,
      type: 'collapse',
      icon: FaMapMarkerAlt,
      breadcrumbs: true,
      children: [
        {
          id: 'State',
          title: <FormattedMessage id="State" />,
          type: 'item',
          url: '/apps/state'
        },
        {
          id: 'City',
          title: <FormattedMessage id="City" />,
          type: 'item',
          url: '/apps/city'
        },
        {
          id: 'Pincode',
          title: <FormattedMessage id="Pincode" />,
          type: 'item',
          url: '/apps/pincode'
        }
      ]
    },
    accessList.includes(16) && {
      id: 'Checklist',
      title: <FormattedMessage id="Checklist" />,
      type: 'collapse',
      icon: FaCheckCircle,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/checklist'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/checklist/manage'
        }
      ]
    },
    accessList.includes(17) && {
      id: 'FAQ',
      title: <FormattedMessage id="FAQ" />,
      type: 'collapse',
      icon: FaQuestionCircle,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/faq'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/faq/manage'
        }
      ]
    },
    accessList.includes(18) && {
      id: 'Charges',
      title: <FormattedMessage id="Charges" />,
      type: 'collapse',
      icon: FaBolt,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/charges'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/apps/charges/manage'
        }
      ]
    },
    accessList.includes(19) && {
      id: 'Taxes',
      title: <FormattedMessage id="Taxes" />,
      type: 'collapse',
      icon: FaPercentage,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/taxes'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/apps/taxes/manage'
        }
      ]
    },
    accessList.includes(20) && {
      id: 'Banners',
      title: <FormattedMessage id="Banners" />,
      type: 'collapse',
      icon: TbPhotoCog ,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/banner-list'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/apps/banner-list/manage'
        }
      ]
    },
    accessList.includes(21) && {
      id: 'Promo Codes',
      title: <FormattedMessage id="Promo Codes" />,
      type: 'collapse',
      icon: HiOutlineTicket,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/promo-code'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/apps/promo-code/manage'
        }
      ]
    },
    accessList.includes(22) && {
      id: 'CMS',
      title: <FormattedMessage id="CMS" />,
      type: 'item',
      icon: FaFileAlt,
      url: '/cms'
    },
    accessList.includes(23) && {
      id: 'Notifications',
      title: <FormattedMessage id="Notifications" />,
      type: 'item',
      icon: FaBell,
      url: '/apps/notifications'
    }
  ].filter(Boolean)
};

export default applications;
