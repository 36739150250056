// ==============================|| THEME CONSTANT ||============================== //

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const APP_DEFAULT_PATH = '/dashboard';
export const Rupee = '₹';
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 280;
export const MINI_DRAWER_WIDTH = 90;
export const HEADER_HEIGHT = 74;
// Role Types
export const ROLE_ADMIN = 1;
export const ROLE_VENDOR = 2;
export const ROLE_SUB_ADMIN = 3;
export const ROLE_CUSTOMER = 4;
export const ROLE_VAN_DRIVER = 5;
export const ROLE_STORE = 6;
export const ROLE_INFLUENCER = 7;
export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const MenuOrientation = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};

export const ThemeDirection = {
  LTR: 'ltr',
  RTL: 'rtl'
};      

export const DropzopType = {
  default: 'DEFAULT',
  standard: 'STANDARD'
};


// ==============================|| REQUEST STATUS ||============================ //

export const STATUS_MAPPING = {
  1: { label: 'Waiting for Approval', color: 'warning' },
  2: { label: 'Request Approved', color: 'success' },
  3: { label: 'Admin Cancelled', color: 'primary' },
  4: { label: 'Partner Cancelled', color: 'error' },
  5: { label: 'Delivered to In-Store', color: 'info' },
  6: { label: 'Return to partner', color: 'warning' },
  7: { label: 'Return accepted', color: 'success' }
};

// ==============================|| GENDER ENUM ||============================ //

export const genderOptions = [
  { value: 1, label: 'Men' }, 
  { value: 2, label: 'Women' }, 
  { value: 3, label: 'Boys' }, 
  { value: 4, label: 'Girls' },
  { value: '0', label: 'All' }
];

// ==============================|| MENU'S ENUM ||============================ //

export const menuList = [
  { "id": 1, "name": "Dashboard" },
  { "id": 2, "name": "Sub Admin" },
  { "id": 3, "name": "Partner" },
  { "id": 4, "name": "In Store" },
  { "id": 5, "name": "Wheelz" },
  { "id": 6, "name": "Customers" },
  { "id": 7, "name": "Influencers" },
  { "id": 8, "name": "Products" },
  { "id": 9, "name": "Bank Accounts" },
  { "id": 10, "name": "Request" },
  { "id": 11, "name": "Orders" },
  { "id": 12, "name": "Return" },
  { "id": 13, "name": "Earnings" },
  { "id": 14, "name": "Configurations" },
  { "id": 15, "name": "Location" },
  { "id": 16, "name": "Checklist" },
  { "id": 17, "name": "FAQ" },
  { "id": 18, "name": "Charges" },
  { "id": 19, "name": "Taxes" },
  { "id": 20, "name": "Banners" },
  { "id": 21, "name": "Promo Codes" },
  { "id": 22, "name": "CMS" },
  { "id": 23, "name": "Notifications" }
]

// ==============================|| THEME CONFIG ||============================== //

const config = {
  /**
   * The props used for the theme font-style.
   * We provide static below options -
   * `'Inter', sans-serif`
   * `'Poppins', sans-serif`
   * `'Roboto', sans-serif`
   * `'Public Sans', sans-serif` (default)
   */
  fontFamily: `Nunito`,

  /**
   * The props used for display menu-items with multi-language.
   * We provide static below languages according to 'react-intl' options - https://www.npmjs.com/package/react-intl
   * 'en' (default)
   * 'fr'
   * 'ro'
   * 'zh'
   */
  i18n: 'en',

  /**
   * the props used for menu orientation (diffrent theme layout).
   * we provide static below options -
   * 'vertical' (default)
   * 'horizontal'
   */
  menuOrientation: MenuOrientation.VERTICAL,

  /**
   * the props used for show/hide caption drawer
   * default - true
   * false - will hide menu caption
   */
  menuCaption: true,

  /**
   * the props used for show mini variant drawer
   * the mini variant is recommended for apps sections that need quick selection access alongside content.
   * default - false
   */
  miniDrawer: false,

  /**
   * the props used for theme container.
   * the container centers your content horizontally. It's the most basic layout element.
   * default - true which show container
   * false - will show fluid
   */
  container: false,

  /**
   * the props used for default theme palette mode
   * explore the default theme
   * below theme options -
   * 'light' (default)
   * 'dark'
   */
  mode: ThemeMode.LIGHT,

  /**
   * the props used for theme primary color variants
   * we provide static below options thoe s are already defaine in src/themes/theme -
   * 'default'
   * 'theme1'
   * 'theme2'
   * 'theme3'
   * 'theme4'
   * 'theme5'
   * 'theme6'
   * 'theme7'
   * 'theme8'
   */
  presetColor: 'default',

  /**
   * the props used for default theme direction
   * explore the default theme
   * below theme options -
   * 'ltr' (default)
   * 'rtl'
   */
  themeDirection: ThemeDirection.LTR,

  /**
   * the props used for theme contrast.
   * set box-shadow .
   * default - false which show card without box-shadow and background default
   * true - will show card with box-shadow and background pure white
   */
  themeContrast: false
};

export default config;
