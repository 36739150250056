// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { FaUserAlt, FaHome, FaShoppingBag, FaTruck, FaHandshake } from 'react-icons/fa';
import { FaUsers } from "react-icons/fa6";

let accessList = JSON.parse(localStorage.getItem('menuAccess')) || [];
const roleId = localStorage.getItem('roleId');

if (roleId === "1") {
  accessList = [1, 2, 3, 4, 5, 6, 7]; // Grant full access by including all menu IDs
}

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'Actors',
  // title: accessList.length > 0 ? <FormattedMessage id="" /> : null,
  // title: <FormattedMessage id={accessList.length > 0 ? "" : ""} />,
  title: accessList.length > 0 ? "" : null,
  icon: FaHome,
  type: 'group',
  children: [
    accessList.includes(1) && {
      id: 'Dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: FaHome,
      breadcrumbs: false
    },
    accessList.includes(2) && {
      id: 'Sub Admin',
      title: <FormattedMessage id="Sub Admin" />,
      type: 'collapse',
      icon: FaUserAlt,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/sub-admin'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/sub-admin/manage'
        }
      ]
    },
    accessList.includes(3) && {
      id: 'Partner',
      title: <FormattedMessage id="Partner" />,
      type: 'collapse',
      icon: FaHandshake,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/vendor'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/vendor/manage'
        }
      ]
    },
    accessList.includes(4) && {
      id: 'In Store',
      title: <FormattedMessage id="In Store" />,
      type: 'collapse',
      icon: FaShoppingBag,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/franchise'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/franchise/manage'
        }
      ]
    },
    accessList.includes(5) && {
      id: 'Wheelz',
      title: <FormattedMessage id="Wheelz" />,
      type: 'collapse',
      icon: FaTruck,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/wheelz'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/wheelz/manage'
        }
      ]
    },
    accessList.includes(6) && {
      id: 'Customers',
      title: <FormattedMessage id="Customers" />,
      type: 'collapse',
      icon: FaUserAlt,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/customer'
        }
      ]
    },
    accessList.includes(7) && {
      id: 'Influencers',
      title: <FormattedMessage id="Influencers" />,
      type: 'collapse',
      icon: FaUsers,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/influencer'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/influencer/manage'
        }
      ]
    }
  ].filter(Boolean)
};

export default widget;
