// third-party
import { FormattedMessage } from 'react-intl';
import { FaBoxOpen, FaCartPlus, FaCog, FaMoneyBillWave } from 'react-icons/fa';
import { CgNotes } from 'react-icons/cg';
import { TbTruckReturn } from 'react-icons/tb';
import { CiBank } from "react-icons/ci";

let accessList = JSON.parse(localStorage.getItem('menuAccess') || "[]");

if (!Array.isArray(accessList)) {
  accessList = [...accessList];
}

const roleId = localStorage.getItem('roleId');

if (roleId === "1") {
  accessList = [8, 9, 10, 11, 12, 13];
}

const hasAccess = accessList.some(id => [8, 9, 10, 11, 12, 13].includes(id));

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const formsTables = {
  id: 'Products',
  title: hasAccess? <FormattedMessage id="Products" /> : null,
  icon: FaCog,
  type: 'group',
  children: [
    accessList.includes(8) && {
      id: 'Products',
      title: <FormattedMessage id="Products" />,
      type: 'collapse',
      icon: FaBoxOpen,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/product'
        }
      ]
    },
    accessList.includes(9) && {
      id: 'Bank Accounts',
      title: <FormattedMessage id="Bank Accounts" />,
      type: 'collapse',
      icon: CiBank,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/bank-accounts'
        }
      ]
    },
    accessList.includes(10) && {
      id: 'Request',
      title: <FormattedMessage id="Request" />,
      type: 'collapse',
      icon: CgNotes,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/request'
        },
        {
          id: 'Return Request',
          title: <FormattedMessage id="Return Request" />,
          type: 'item',
          url: '/request/return-request'
        }
      ]
    },
    accessList.includes(11) && {
      id: 'Orders',
      title: <FormattedMessage id="Orders" />,
      type: 'collapse',
      icon: FaCartPlus,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/order'
        },
        {
          id: 'Product Return',
          title: <FormattedMessage id="Product Return" />,
          type: 'item',
          url: '/order/return'
        }
      ]
    },
    accessList.includes(12) && {
      id: 'Return',
      title: <FormattedMessage id="Return" />,
      type: 'collapse',
      icon: TbTruckReturn,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/return'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/return/manage'
        }
      ]
    },
    accessList.includes(13) && {
      id: 'Earnings',
      title: <FormattedMessage id="Earnings" />,
      type: 'collapse',
      icon: FaMoneyBillWave,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/earnings'
        },
        {
          id: 'Partners Earnings',
          title: <FormattedMessage id="Partners Earnings" />,
          type: 'item',
          url: '/earnings/partners-earnings'
        },
        {
          id: 'Partners Rent',
          title: <FormattedMessage id="Partners Rent" />,
          type: 'item',
          url: '/earnings/partners-rent'
        }
      ]
    }
  ].filter(Boolean)
};

export default formsTables;
